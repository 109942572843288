<script setup>
import FooterBar from "./components/FooterBar.vue";
import NavBar from "./components/NavBar.vue";
import {
  CHAIN_ID,
  NATIVE_TOKEN_SYMBOL,
  CHAIN_NAME,
  RPC_URL,
} from "./config.js";

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue'


// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'cd1f13d80589ea1d83912de8430b8523'

// 2. Set chains
const mainnet = {
  chainId: CHAIN_ID,
  name: CHAIN_NAME,
  currency: NATIVE_TOKEN_SYMBOL,
  explorerUrl: 'https://etherscan.io',
  rpcUrl: RPC_URL
}

// 3. Create your application's metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // url must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
const modal = createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
})

</script>
<template>
  <div id="app">
    <nav-bar :modal="modal"></nav-bar>
    <router-view :modal="modal"/>
    <footer-bar></footer-bar>
    <notifications/>
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.03em;
  color: white;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: black;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
}
input {
  outline: none;
  border: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
button {
  border: none;
  cursor: pointer;
}

.move-text {
  position: relative;
  width: fit-content;
}

.move-line {
  height: 1px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 20px;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Regular;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}

@media screen and (max-width: 1600px) {
  .launchpad {
    transform: scale(0.9);
    transform-origin: center 0;
    margin-bottom: -350px !important;
  }
  .buy {
    transform: scale(0.9);
    transform-origin: center 0;
    margin-bottom: -300px !important;
  }
  .home {
    transform: scale(0.8);
    transform-origin: center 0;
    margin-bottom: -600px !important;
  }
  /* .airdrop-view {
    transform: scale(0.8);
    transform-origin: center 0;
  } */
}
</style>
