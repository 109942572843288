

let SERVER_DOMAIN,CHAIN_ID, NATIVE_TOKEN_SYMBOL, CHAIN_NAME, RPC_URL, PRESEALCONTRACT, PADCONTRACT,CONCONTRACT

if (process.env.VUE_APP_ENV === 'production') {
  CHAIN_ID = '0x144'
  NATIVE_TOKEN_SYMBOL = 'ETH'
  CHAIN_NAME = 'zkSync Era'
  RPC_URL = 'https://zksync2-mainnet.zksync.io'
  PRESEALCONTRACT = '0x04c1c626c19f593f66da18b1574563701d809e18';
  PADCONTRACT = '0xc076682Aba6287aFF7AfD00C15C8169440e3d441'
  CONCONTRACT=""
  SERVER_DOMAIN = "https://api.syncpad.finance/api"
}if (process.env.VUE_APP_ENV === 'ppt') {
  CHAIN_ID = '0x144'
  NATIVE_TOKEN_SYMBOL = 'ETH'
  CHAIN_NAME = 'zkSync Era'
  RPC_URL = 'https://zksync2-mainnet.zksync.io'
  PRESEALCONTRACT = '0x04c1c626c19f593f66da18b1574563701d809e18';
  PADCONTRACT = '0xc076682Aba6287aFF7AfD00C15C8169440e3d441'
  CONCONTRACT=""
  SERVER_DOMAIN = "https://api.syncpad.finance/api"
} else if (process.env.VUE_APP_ENV === 'test') {
  CHAIN_ID = '0x12c';
  NATIVE_TOKEN_SYMBOL = 'ETH';
  CHAIN_NAME = 'sepolia.era.zksync.dev';
  RPC_URL = 'https://sepolia.era.zksync.dev';
  PRESEALCONTRACT = '0x04c1c626c19f593f66da18b1574563701d809e18';
  PADCONTRACT = '0xc076682Aba6287aFF7AfD00C15C8169440e3d441'
  CONCONTRACT=""
  SERVER_DOMAIN = "https://api.syncpad.finance/api"
} else if (process.env.VUE_APP_ENV === 'dev') {
  CHAIN_ID = '0x12c';
  NATIVE_TOKEN_SYMBOL = 'ETH';
  CHAIN_NAME = 'sepolia.era.zksync.dev';
  RPC_URL = 'https://sepolia.era.zksync.dev';
  PRESEALCONTRACT = '0x04c1c626c19f593f66da18b1574563701d809e18';
  PADCONTRACT = '0xc076682Aba6287aFF7AfD00C15C8169440e3d441'
  CONCONTRACT=""
  SERVER_DOMAIN = "http://192.168.3.17:3000"
}

export { SERVER_DOMAIN, CHAIN_ID, NATIVE_TOKEN_SYMBOL, CHAIN_NAME, RPC_URL, PRESEALCONTRACT, PADCONTRACT,CONCONTRACT }