<template>
  <footer>
    <div class="brand">
      <img src="../assets/footer-logo.png" class="brand-logo" />
      <p class="brand-desc">©2018 - Form | All right reserved</p>
    </div>
    <div class="links">
      <div class="links-line">
        <p class="links-title">Resources</p>
        <a
          target="_blank"
          href="https://syncpaddoc.gitbook.io/syncpad/security/audit"
          >Audit</a
        >
        <a target="_blank" href="https://syncpaddoc.gitbook.io/syncpad/contacts"
          >Contact us</a
        >
        <a
          target="_blank"
          href="https://syncpaddoc.gitbook.io/syncpad/security/contract"
          >Contracts</a
        >
        <a
          target="_blank"
          href="https://syncpaddoc.gitbook.io/syncpad/security/documention"
          >Documentation</a
        >
      </div>
      <div class="links-line">
        <p class="links-title">Help</p>
        <a
          target="_blank"
          href="https://syncpaddoc.gitbook.io/syncpad/start-here"
          >FAQs</a
        >
        <a
          target="_blank"
          href="https://syncpaddoc.gitbook.io/syncpad/start-here/how-to-swap-on-syncpad"
          >Guides</a
        >
        <a
          target="_blank"
          href="https://syncpaddoc.gitbook.io/syncpad/contacts/user-support"
          >Support</a
        >
      </div>
      <div class="links-line">
        <p class="links-title">Ecosystem</p>
        <a target="_blank" href="https://www.coingecko.com/">CoinGecko</a>
        <a target="_blank" href="https://explorer.zksync.io/">Block Explorer</a>
        <a
          target="_blank"
          href="https://era.zksync.io/docs/dev/building-on-zksync/hello-world.html"
          >Decko Terminal</a
        >
        <a target="_blank" href="https://snapshot.org/#/?q=zksync"
          >Governance</a
        >
      </div>
    </div>
    <div class="links-line">
      <p class="links-title">Follow us</p>
      <div class="links-media-wrap">
        <a target="_blank" href="https://twitter.com/syncpad_zks">
          <img src="../assets/twitter.png" class="links-media twitter"
        /></a>
        <a target="_blank" href="https://discord.gg/syncpadfinance">
          <img src="../assets/discord.png" class="links-media discord"
        /></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
};
</script>
<style lang="less">
footer {
  display: flex;
  justify-content: space-between;
  padding-top: 96px;
  // padding-left: 103px;
  // padding-right: 283px;
  padding-left: 5.5vw;
  padding-right: 15vw;
  height: 300px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  .brand {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-logo {
      width: 95px;
    }
    &-desc {
      opacity: 0.6;
      font-size: 12px;
      margin-top: 20px;
      white-space: nowrap;
      font-family: "Visby CF";
    }
  }

  .links {
    display: flex;

    &-line {
      font-weight: 600;
      font-family: "Visby CF";

      &:nth-of-type(1) {
        width: 197px;
      }

      &:nth-of-type(2) {
        width: 141px;
      }
    }

    &-title {
      font-size: 13px;
    }

    a {
      margin-top: 10px;
      opacity: 0.6;
      display: block;
      font-size: 12px;

      &:hover,
      &:active {
        opacity: 1;
      }
    }

    &-media-wrap {
      margin-top: 22px;
      display: flex;
    }

    &-media {
      cursor: pointer;

      &.twitter {
        height: 18px;
      }
      &.discord {
        height: 20px;
        margin-left: 30px;
      }
    }
  }
}
</style>