var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"title-wrap",attrs:{"id":"about"}},[_c('p',{staticClass:"title"},[_vm._v("SyncPad")]),_c('p',{staticClass:"title"},[_vm._v("One-Stop Cross")]),_c('p',{staticClass:"title"},[_vm._v("Chain Launchpad")]),_vm._m(0),_c('router-link',{staticClass:"enter-dapp",attrs:{"to":"launchpad"},nativeOn:{"mouseenter":function($event){return _vm.enterButton('moveText1')},"mouseleave":function($event){return _vm.leaveButton('moveText1')}}},[_c('div',{staticClass:"enter-dapp__text move-text"},[_vm._v(" "+_vm._s(_vm.moveText1)+" "),_c('span',{staticClass:"move-line",style:({
      width: `${(this.stepMap.moveText1 / this.moveText1.length) * 100
        }%`,
    })})])])],1),_c('div',{staticClass:"brand-wrap",attrs:{"id":"tasks"}},[_c('img',{staticClass:"brand",attrs:{"src":require("../assets/syncpad.gif")}}),_c('div',{staticClass:"links-wrap"},[_c('p',{staticClass:"links-title"},[_vm._v("Join to earn OAT & Points")]),_c('a',{staticClass:"link",attrs:{"target":"_blank","href":"https://zealy.io/c/syncpadfinance/questboard"},on:{"mouseenter":function($event){return _vm.enterButton('moveText2')},"mouseleave":function($event){return _vm.leaveButton('moveText2')}}},[_c('div',{staticClass:"move-text"},[_vm._v(" "+_vm._s(_vm.moveText2)+" "),_c('span',{staticClass:"move-line",style:({
      width: `${(this.stepMap.moveText2 / this.moveText2.length) * 100
        }%`,
    })})]),_c('img',{attrs:{"src":require("../assets/enter.png")}})]),_c('a',{staticClass:"link",attrs:{"target":"_blank","href":"https://app.questn.com/event/887635557412217214"},on:{"mouseenter":function($event){return _vm.enterButton('moveText3')},"mouseleave":function($event){return _vm.leaveButton('moveText3')}}},[_c('div',{staticClass:"move-text"},[_vm._v(" "+_vm._s(_vm.moveText3)+" "),_c('span',{staticClass:"move-line",style:({
      width: `${(this.stepMap.moveText3 / this.moveText3.length) * 100
        }%`,
    })})]),_c('img',{attrs:{"src":require("../assets/enter.png")}})])])]),_c('div',{staticStyle:{"height":"300px"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subtitle-wrap"},[_c('p',{staticClass:"subtitle"},[_vm._v("Born from zkSync")]),_c('p',{staticClass:"subtitle"},[_vm._v("the cutting-edge cross-chain adventure begins")])])
}]

export { render, staticRenderFns }