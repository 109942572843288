import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "animate.css";
import Notifications from 'vue-notification'
import store from'./store';
import VueClipboard from 'vue-clipboard2'

Vue.use(Notifications)
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.mixin({
  data() {
    return {
      moveText1: "ENTER DAPP",
      moveText2: "zealy link",
      moveText3: "QuestN link",
      moveText4: "SoQuest link",
      moveText5: "Efficiency and convenience",
      moveText6: "Multiple mixed auction modes",
      moveText7: "Innovative leveraged launches",
      moveText8: "Permissionless tools",
      moveText9: "Efficiency and convenience",
      moveText10: 'ON GOING',
      moveText11: 'ON GOING',
      moveText12: 'Claim',
      moveText13: 'Approve',
      moveText14: 'Buy',
      moveText15: 'Home',
      moveText16: 'Features',
      moveText17: 'Tasks',
      moveText18: 'Partner',
      intervalMap: {
        moveText1: null,
        moveText2: null,
        moveText3: null,
        moveText4: null,
        moveText5: null,
        moveText6: null,
        moveText7: null,
        moveText8: null,
        moveText9: null,
        moveText10: null,
        moveText11: null,
        moveText12: null,
        moveText13: null,
        moveText14: null,
        moveText15: null,
        moveText16: null,
        moveText17: null,
        moveText18: null,
      },
      stepMap: {
        moveText1: 0,
        moveText2: 0,
        moveText3: 0,
        moveText4: 0,
        moveText5: 0,
        moveText6: 0,
        moveText7: 0,
        moveText8: 0,
        moveText9: 0,
        moveText10: 0,
        moveText11: 0,
        moveText12: 0,
        moveText13: 0,
        moveText14: 0,
        moveText15: 0,
        moveText16: 0,
        moveText17: 0,
        moveText18: 0
      },
    };
  },
  methods: {
    enterButton(key, isFast) {
      const INTERVAL = isFast ? 10 : 30
      if (this.intervalMap[key]) {
        clearInterval(this.intervalMap[key]);
      }
      this.intervalMap[key] = setInterval(() => {
        const text = this[key];
        const charList = text.split("");
        const char = charList.pop();
        charList.unshift(char);
        this[key] = charList.join("");
        this.stepMap[key] = this.stepMap[key] + 1;
        if (this.stepMap[key] === this[key].length && this.intervalMap[key]) {
          clearInterval(this.intervalMap[key]);
          return;
        }
      }, INTERVAL);
    },
    leaveButton(key, isFast) {
      const INTERVAL = isFast ? 10 : 30
      if (this.intervalMap[key]) {
        clearInterval(this.intervalMap[key]);
      }
      if (!this.stepMap[key]) {
        return;
      }
      this.intervalMap[key] = setInterval(() => {
        const text = this[key];
        const charList = text.split("");
        const char = charList.shift();
        charList.push(char);
        this[key] = charList.join("");
        this.stepMap[key] = this.stepMap[key] - 1;
        if (!this.stepMap[key]) {
          clearInterval(this.intervalMap[key]);
          return;
        }
      }, INTERVAL);
    },
  },
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
