import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lsAddress: '',
    loginType:'',
    provider:null,
    signer:null,
  },
  mutations: {
    setLsAddress(state, addr) {
      state.lsAddress = addr
    },
    setLoginType(state, ltype) {
      state.loginType = ltype
    },
    setProvider(state, provider_) {
      state.provider = provider_
    },
    setSigner(state, signer_) {
      state.signer = signer_
    }
  }
})
