import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
/*     redirect: () => {
      return { path: '/homeView' }
    }, */
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/launchpad',
    name: 'launchpad',
    component: () => import('../views/LaunchpadView.vue')
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import('../views/BuyView.vue')
  },
  {
    path: '/airdrop',
    name: 'airdrop',
    component: () => import('../views/AirdropView.vue')
  },
  {
    path: '/airdrop2',
    name: 'airdrop2',
    component: () => import('../views/AirdropView2.vue')
  },
  {
    path: '/airdrop3',
    name: 'airdrop3',
    component: () => import('../views/AirdropView3.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
