<template>
  <div class="home">
    <div class="title-wrap" id="about">
      <p class="title">SyncPad</p>
      <p class="title">One-Stop Cross</p>
      <p class="title">Chain Launchpad</p>
      <div class="subtitle-wrap">
        <p class="subtitle">Born from zkSync</p>
        <p class="subtitle">the cutting-edge cross-chain adventure begins</p>
      </div>
      <router-link class="enter-dapp" to="launchpad" @mouseenter.native="enterButton('moveText1')"
        @mouseleave.native="leaveButton('moveText1')">
        <div class="enter-dapp__text move-text">
          {{ moveText1 }}
          <span class="move-line" :style="{
        width: `${(this.stepMap.moveText1 / this.moveText1.length) * 100
          }%`,
      }"></span>
        </div>
      </router-link>
      <!-- <p class="float-text float-text1" id="features">
        <span>{{ moveText5 }}</span>
      </p>
      <p class="float-text float-text2">
        <span>{{ moveText6 }}</span>
      </p>
      <p class="float-text float-text3">
        <span>{{ moveText7 }}</span>
      </p>
      <p class="float-text float-text4">
        <span>{{ moveText8 }}</span>
      </p>
      <p class="float-text float-text5">
        <span>{{ moveText9 }}</span>
      </p> -->
    </div>
    <div class="brand-wrap" id="tasks">
      <img src="../assets/syncpad.gif" class="brand" />
      <div class="links-wrap">
        <p class="links-title">Join to earn OAT & Points</p>
        <a class="link" target="_blank" href="https://zealy.io/c/syncpadfinance/questboard"
          @mouseenter="enterButton('moveText2')" @mouseleave="leaveButton('moveText2')">
          <div class="move-text">
            {{ moveText2 }}
            <span class="move-line" :style="{
        width: `${(this.stepMap.moveText2 / this.moveText2.length) * 100
          }%`,
      }"></span>
          </div>
          <img src="../assets/enter.png" />
        </a>
        <a class="link" target="_blank" href="https://app.questn.com/event/887635557412217214"
          @mouseenter="enterButton('moveText3')" @mouseleave="leaveButton('moveText3')">
          <div class="move-text">
            {{ moveText3 }}
            <span class="move-line" :style="{
        width: `${(this.stepMap.moveText3 / this.moveText3.length) * 100
          }%`,
      }"></span>
          </div>
          <img src="../assets/enter.png" />
        </a>
        <!-- <a
          class="link"
          target="_blank"
          href="https://soquest.xyz/space/SyncPad"
          @mouseenter="enterButton('moveText4')"
          @mouseleave="leaveButton('moveText4')"
        >
          <div class="move-text">
            {{ moveText4 }}
            <span
              class="move-line"
              :style="{
                width: `${
                  (this.stepMap.moveText4 / this.moveText4.length) * 100
                }%`,
              }"
            ></span>
          </div>
          <img src="../assets/enter.png" />
        </a> -->
      </div>
    </div>
    <div style="height: 300px;"></div>
    <!-- <img id="partners" class="partners" src="../assets/partners.png" /> -->
    <!-- <div class="zksync-areas">
      <p>6. zkSync Area:</p>
      <p>
        - Highlighting that SyncPad was born on the zkSync ecosystem,
        emphasizing the close connection with zkSync
      </p>
      <p>built on: zkSync</p>
      <p>
        partner:QuestN, Zealy, zkSync Space, zkSync Ecosystem, zkSync Insider,
        zkSync ID and more
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  mounted() { },
  methods: {
    showAnimation() { },
  },
};
</script>
<style lang="less">
.home {
  .title-wrap {
    margin: 66px auto 0;
    text-align: center;
    width: fit-content;

    .title {
      font-weight: 600;
      font-size: 118px;
    }
  }

  .subtitle-wrap {
    margin-top: 23px;
    text-align: left;

    .subtitle {
      font-size: 38px;
      line-height: 80px;
    }
  }

  .enter-dapp {
    // padding: 10px 103px;
    padding: 10px 0;
    width: 300px;
    text-align: center;
    background-color: #2b67ff;
    font-size: 24px;
    margin: 105px auto 0;
    display: block;
    // width: fit-content;

    &__text {
      margin: 0 auto;

      .move-line {
        top: 25px;
      }
    }
  }

  .float-text {
    font-size: 52px;
    margin-top: 100px;

    span {
      font-size: 52px;
      display: inline-block;

      // &:hover {
      //   animation: identifier 1s;

      //   @keyframes identifier {
      //     0% {
      //       transform: rotateY(0);
      //     }
      //     50% {
      //       transform: rotateY(180deg);
      //     }
      //     100% {
      //       transform: rotateY(0);
      //     }
      //   }
      // }
    }

    &1 {
      margin-top: 295px;
      text-align: left;
    }

    &2 {
      text-align: right;
      margin-right: -90px;
    }

    &3 {
      margin-left: -129px;
      text-align: left;
    }

    &4 {
      margin-left: 61px;
      text-align: left;
    }

    &4 {
      margin-right: 5px;
      text-align: right;
    }
  }

  .brand-wrap {
    margin: 202px auto 0;
    display: flex;
    justify-content: center;

    .brand {
      width: 1276px;
      height: fit-content;
      margin-left: -300px;
    }

    .links {
      &-wrap {
        margin-left: -110px;
        margin-top: 400px;

        a {
          display: block;
          position: relative;

          &:nth-of-type(1) {
            img {
              position: absolute;
              right: 30px;
              top: 0;
              bottom: 0;
              margin: auto;
            }

            div {
              transform: translateX(-20px);
            }
          }

          &:nth-of-type(2) {
            img {
              position: absolute;
              right: 25px;
              top: 0;
              bottom: 0;
              margin: auto;
            }

            div {
              transform: translateX(-15px);
            }
          }

          &:nth-of-type(3) {
            img {
              position: absolute;
              right: 25px;
              top: 0;
              bottom: 0;
              margin: auto;
            }

            div {
              transform: translateX(-15px);
            }
          }
        }

        .link {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 70px;
          width: 272px;
          margin-bottom: 30px;
          border: 1px solid #ffffff;

          div {
            font-size: 24px;

            span {
              top: 30px;
            }
          }

          img {
            width: 28px;
            margin-left: 28px;
          }
        }
      }

      &-title {
        color: #2b67ff;
        margin-bottom: 40px;
        font-size: 20px;
      }
    }
  }

  .partners {
    width: 1237px;
    margin: 78px auto 0;
    display: block;
  }

  .zksync-areas {
    margin: 130px auto 0;
    width: 992px;
    transform: translateX(100px);

    p {
      font-size: 52px;
    }
  }
}
</style>