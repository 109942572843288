<template>
  <nav :class="{ 'nav-shrink': $route.path !== '/' }">
    <router-link to="/">
      <img src="../assets/nav-logo.png" class="nav-logo" />
    </router-link>
    <div class="nav-menu" v-if="$route.path !== '/'">
      <router-link to="/swap" class="nav-menu-item disabled">Swap</router-link>
      <router-link to="/pool" class="nav-menu-item disabled">Pool</router-link>
      <!-- <router-link to="/staking" class="nav-menu-item disabled"
        >Bridge</router-link
      > -->
      <div class="nav-menu-item nav-menu-item-bridge-wrap">
        <div class="nav-menu-item-bridge-cancel" v-if="bridgeMenuVisible" @click="bridgeMenuVisible = false"></div>
        <span @click="bridgeMenuVisible = true">Bridge</span>
        <div class="bridge-menu" v-if="bridgeMenuVisible">
          <p @click="toUrl('https://bridge.zksync.io/')">zkSync Era</p>
          <p @click="toUrl('https://cbridge.celer.network/')">Celer</p>
          <p @click="toUrl('https://www.orbiter.finance/')">Orbiter Finance</p>
        </div>
      </div>
      <router-link :to="{ path: '/launchpad', query: $route.query }" class="nav-menu-item-with-icon">
        <span>Launchpad</span>
        <img src="../assets/fire.png" />
      </router-link>
      <router-link to="/tokenTool" class="nav-menu-item disabled">Token Tool</router-link>
    </div>
    <div class="nav-menu" v-else>
      <div class="nav-menu-item" @click="slideTo('#about')">
        <div class="move-text" @mouseenter="enterButton('moveText15')" @mouseleave="leaveButton('moveText15')">
          {{ moveText15 }}
          <span class="move-line" :style="{
    width: `${(this.stepMap.moveText15 / this.moveText15.length) * 100
      }%`,
  }"></span>
        </div>
      </div>
      <!-- <div class="nav-menu-item" @click="slideTo('#features')">
        <div
          class="move-text"
          @mouseenter="enterButton('moveText16')"
          @mouseleave="leaveButton('moveText16')"
        >
          {{ moveText16 }}
          <span
            class="move-line"
            :style="{
              width: `${
                (this.stepMap.moveText16 / this.moveText16.length) * 100
              }%`,
            }"
          ></span>
        </div>
      </div> -->
      <div class="nav-menu-item" @click="slideTo('#tasks')">
        <div class="move-text" @mouseenter="enterButton('moveText17')" @mouseleave="leaveButton('moveText17')">
          {{ moveText17 }}
          <span class="move-line" :style="{
    width: `${(this.stepMap.moveText17 / this.moveText17.length) * 100
      }%`,
  }"></span>
        </div>
      </div>
      <!-- <router-link
        :to="{ path: '/launchpad', query: $route.query }"
        class="nav-menu-item-with-icon"
      >
        <span>Tasks</span>
        <img src="../assets/fire.png" />
      </router-link> -->
      <!-- <div class="nav-menu-item" @click="slideTo('#partners')">
        <div
          class="move-text"
          @mouseenter="enterButton('moveText18')"
          @mouseleave="leaveButton('moveText18')"
        >
          {{ moveText18 }}
          <span
            class="move-line"
            :style="{
              width: `${
                (this.stepMap.moveText18 / this.moveText18.length) * 100
              }%`,
            }"
          ></span>
        </div>
      </div> -->
    </div>
    <div class="nav-slide-cancel" @click="navSlideVisible = false" v-show="navSlideVisible"></div>
    <div class="nav-tools">
      <div class="nav-tools-airdrop" v-if="$route.path !== '/'">
        <span @click="airDropVisible = true" class="nav-menu-item airdrop">Airdrop</span>
        <div class="nav-tools-airdrop-cancel" v-if="airDropVisible" @click="airDropVisible = false"></div>
        <div class="nav-tools-airdrop-menu" v-if="airDropVisible">
          <p @click="goAirDrop(1)">Airdrop#1</p>
          <p @click="goAirDrop(2)">Airdrop#2</p>
          <p @click="goAirDrop(3)">Airdrop#3</p>
        </div>
      </div>
      <div class="nav-medias" v-if="$route.path === '/'">
        <a target="_blank" href="https://twitter.com/syncpad_zks">
          <img src="../assets/twitter.png" class="nav-media twitter" /></a>
        <a target="_blank" href="https://discord.gg/syncpadfinance">
          <img src="../assets/discord.png" class="nav-media discord" /></a>
      </div>
      <div class="nav-tools-network" v-if="$route.path !== '/'">
        <img src="../assets/zksync.png" />
        <span class="nav-tools-network-name">Zksync Era</span>
        <span class="nav-tools-network-arrow">&gt;</span>
      </div>
      <div class="nav-tools-address" v-if="$route.path !== '/'">
        <div class="nav-tools-address-loginCancel" v-if="addressLoginVisible" @click="addressLoginVisible = false">
        </div>
        <div class="nav-tools-address-loginMenu" v-if="addressLoginVisible">
          <div><img src="../assets/gateWallet.png" />
            <p @click="login(1)">Gate Wallet</p>
          </div>
          <div><img src="../assets/bitgetWallet.jpg" />
            <p @click="login(2)">Bitget Wallet</p>
          </div>
          <div><img src="../assets/okxWallet.png" />
            <p @click="login(3)">OKX Wallet</p>
          </div>
          <div><img src="../assets/metamask.png" />
            <p @click="login(5)">MetaMask Wallet</p>
          </div>
          <div><img src="../assets/connectWallet.png" />
            <p @click="login(4)">Connect Wallet</p>
          </div>
        </div>

        <!-- <w3m-button size="sm"/> -->
        <span @click="preLoging" class="nav-tools-address-name">{{
    lsAddress
      ? parseOmit(lsAddress, 4, 4)
      : "Connect"
  }}</span>
        <span class="nav-tools-address-arrow" @click="disConnectVisible = true">&gt;</span>
        <div class="nav-tools-address-cancel" v-if="disConnectVisible" @click="disConnectVisible = false"></div>
        <div class="nav-tools-address-menu" v-if="disConnectVisible">
          <p @click="disConnect">Disconnect</p>
        </div>
      </div>

      <div class="nav-btn" v-if="$route.path !== '/'" @click="navSlideVisible = true">
        <img src="../assets/menu.png" class="nav-btn-icon" />
        <div class="nav-slide" v-show="navSlideVisible">
          <!-- <div class="nav-slide-cancel" @click="navSlideVisible = false"></div> -->
          <!--           <a class="nav-slide-item nav-slide-item0" @click="toUrl('https://mirror.xyz/')">
            <img src="../assets/blog-white.png" />
            <span>Blog</span>
          </a> -->
          <a class="nav-slide-item nav-slide-item1" @click="toUrl('https://syncpaddoc.gitbook.io/syncpad/')">
            <img src="../assets/doc.png" />
            <span>Docs</span>
          </a>
          <a class="nav-slide-item nav-slide-item2" @click="toUrl('https://twitter.com/syncpad_zks')">
            <img src="../assets/twitter-white.png" />
            <span>Twitter</span>
          </a>
          <a class="nav-slide-item nav-slide-item3" @click="toUrl('https://discord.gg/syncpadfinance')">
            <img src="../assets/discord-white.png" />
            <span>Discord</span>
          </a>
          <div class="nav-slide-divider"></div>
          <a class="nav-slide-item nav-slide-item4">
            <img src="../assets/vote.png" />
            <span>Vote in governance</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  CHAIN_ID,
  NATIVE_TOKEN_SYMBOL,
  CHAIN_NAME,
  RPC_URL,
} from "../config.js";
import { mapState } from 'vuex'
import { useWeb3Modal, useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers/vue'
import { ethers, BrowserProvider, parseUnits, parseEther, formatEther, formatUnits } from "ethers";
const { disconnect } = useDisconnect()

export default {
  name: "NavBar",
  props: ['modal'],
  data() {
    return {
      navSlideVisible: false,
      bridgeMenuVisible: false,
      disConnectVisible: false,
      airDropVisible: false,
      addressLoginVisible: false,
      chainId: null,
      addressTemp: null,
    };
  },
  computed: {
    ...mapState(['lsAddress']),
    ...mapState(['loginType']),
    ...mapState(['provider']),
    ...mapState(['signer']),
  },
  created() {
    console.log("init provider");
    const provider_ = new ethers.JsonRpcProvider(RPC_URL)
    this.$store.commit('setProvider', provider_)

    console.log("init modal address");
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    this.addressTemp = address
    this.chainId = chainId
  },
  watch: {
    async addressTemp(newVal, oldVal) {
      console.log("addressTemp", newVal, oldVal);
      if (newVal) {
        if (this.loginType == 4) {
          this.$store.commit('setLsAddress', newVal)
          const walletProvider = this.modal.getWalletProvider()
          const provider_ = new ethers.BrowserProvider(walletProvider)
          this.$store.commit('setProvider', provider_)
          const singer_ = await provider_.getSigner();
          this.$store.commit('setSigner', singer_)
          await this.switchNetwork()
        }
      }
    }
  },
  methods: {
    goAirDrop(val) {
      if (val == 1) {
        this.$router.push({ path: "/airdrop", });
      } else if (val == 2) {
        this.$router.push({ path: "/airdrop2", });
      } else if (val == 3) {
        this.$router.push({ path: "/airdrop3", });
      }
    },
    parseOmit(text) {
      return text.length > 8
        ? `${text.substring(0, 4)}...${text.substring(text.length - 4)}`
        : text;
    },
    toUrl(url) {
      this.navSlideVisible = false;
      this.bridgeMenuVisible = false;
      window.open(url);
    },
    preLoging() {
      if (this.lsAddress) {
        this.disConnectVisible = true
      } else {
        this.addressLoginVisible = true
      }
    },
    async login(ltype) {
      this.addressLoginVisible = false
      console.log("login", this.lsAddress)
      if (this.lsAddress) {
        this.disConnectVisible = true
        return
      }

      this.$store.commit('setLoginType', ltype)
      let provider_
      if (ltype == 1) {
        if (typeof window.gatewallet !== 'undefined') {
          console.log('Gate Wallet is installed!');
          const accounts = await gatewallet.request({
            method:
              'eth_requestAccounts'
          })
            .catch((error) => {
              if (error.code === 4001) {
                // EIP-1193 userRejectedRequest error
                console.log('Please connect to Gate Wallet.');
              } else {
                console.error(error);
              }
            });

          provider_ = new ethers.BrowserProvider(window.ethereum)
          this.$store.commit('setProvider', provider_)
          const singer_ = await provider_.getSigner();
          this.$store.commit('setSigner', singer_)
          this.$store.commit('setLsAddress', accounts[0])
          await this.switchNetwork()
        } else {
          this.$notify({ type: 'error', text: 'Gate Wallet is not installed' })
        }
      } else if (ltype == 2) {
        const provider = window.bitkeep && window.bitkeep.ethereum;

        if (provider) {
          console.log('Bitget is installed!');
          const accounts = await provider.request({
            method: "eth_requestAccounts",
          }).catch(e => {
            console.log(e);
          });
          provider_ = new ethers.BrowserProvider(window.ethereum)
          this.$store.commit('setProvider', provider_)
          const singer_ = await provider_.getSigner();
          this.$store.commit('setSigner', singer_)
          this.$store.commit('setLsAddress', accounts[0])
          await this.switchNetwork()
        } else {
          this.$notify({ type: 'error', text: 'Bitget Wallet is not installed' })
        }

      } else if (ltype == 3) {
        if (typeof window.okxwallet !== 'undefined') {
          console.log('OKX is installed!');
          const accounts = await window.okxwallet.request({
            method: "eth_requestAccounts",
          }).catch(e => {
            console.log();
          });
          provider_ = new ethers.BrowserProvider(window.ethereum)
          this.$store.commit('setProvider', provider_)
          const singer_ = await provider_.getSigner();
          this.$store.commit('setSigner', singer_)
          this.$store.commit('setLsAddress', accounts[0])
          await this.switchNetwork()
        } else {
          this.$notify({ type: 'error', text: 'OKX Wallet is not installed' })
        }

      } else if (ltype == 5) {
        if (typeof window.ethereum !== 'undefined') {
          console.log('metamask is installed!');
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          }).catch(e => {
            console.log();
          });
          provider_ = new ethers.BrowserProvider(window.ethereum)
          this.$store.commit('setProvider', provider_)
          const singer_ = await provider_.getSigner();
          this.$store.commit('setSigner', singer_)
          this.$store.commit('setLsAddress', accounts[0])
          await this.switchNetwork()
        } else {
          this.$notify({ type: 'error', text: 'OKX Wallet is not installed' })
        }

      } else if (ltype == 4) {
        await this.modal.open()
      }
    },
    async disConnect() {
      console.log("disConnect", this.lsAddress)
      this.disConnectVisible = false
      if (this.loginType == 4) {
        disconnect()
      }
      this.addressTemp = ""
      this.$store.commit('setLsAddress', "")
      this.$store.commit('setLoginType', "")
      const provider_ = new ethers.JsonRpcProvider(RPC_URL)
      this.$store.commit('setProvider', provider_)
      this.$store.commit('setSigner', null)
    },
    slideTo(id) {
      const dom = document.querySelector(id);
      console.log(dom);
      if (dom) {
        window.scrollTo({
          top: dom.getBoundingClientRect().top,
          behavior: "smooth",
        });
      }
    },
    async switchNetwork() {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: CHAIN_ID }],
      }).then(() => {
        console.log("Switched to ", CHAIN_ID);
      })
        .catch(async (error) => {
          console.error("Error switching network:", error);

          await this.request({
            "method": "wallet_addEthereumChain",
            "params": [
              {
                "chainId": CHAIN_ID,
                "chainName": CHAIN_NAME,
                "rpcUrls": [
                  RPC_URL
                ],
                "nativeCurrency": {
                  "name": NATIVE_TOKEN_SYMBOL,
                  "symbol": NATIVE_TOKEN_SYMBOL,
                  "decimals": 18
                }
              }
            ]
          });
        });;
    },
  }
};
</script>
<style lang="less">
nav {
  height: 80px;
  min-width: 1395px;
  padding-right: 97px;
  display: flex;
  align-items: center;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  padding-top: 10px;

  @media screen and (max-width: 1600px) {
    padding-right: 70px;
    height: 75px;

    .nav-logo {
      margin-left: 70px !important;
    }
  }

  .nav-logo {
    height: 54px;
    margin-left: 97px;
  }

  .nav-menu {
    margin-left: 77px;
    display: flex;
    // transform: translateY(6px);
    // padding-top: 6px;

    &-item {
      cursor: pointer;
      // opacity: 0.5;
      padding: 0 37px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }

      // &:hover,
      // &:active {
      //   opacity: 1;
      // }

      &-with-icon {
        display: flex;
        // align-items: center;
        padding: 0 37px;

        span {
          // opacity: 0.5;

          &:hover,
          &:active {
            opacity: 1;
          }
        }

        img {
          height: 16px;
          margin-left: 2px;
        }
      }

      &.airdrop {
        padding: 3px 12px;
        background: white;
        color: black;
        height: fit-content;
        // margin-left: 100px;
        font-family: Regular;
      }

      &-bridge {
        &-wrap {
          cursor: initial;

          span {
            cursor: pointer;
          }
        }

        &-cancel {
          position: fixed;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 998;
        }

        &-wrap {
          position: relative;

          .bridge-menu {
            top: 30px;
            width: 169px;
            border: 0.5px solid #FFF;
            background: #020511;
            padding: 16px 0 12px 21px;
            position: absolute;
            z-index: 999;
            cursor: pointer;

            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
  }

  .nav-tools {
    display: flex;
    align-items: center;
    // transform: translateY(7px);
    margin-left: auto;
    z-index: 999;

    &-network {
      padding: 2px 10px;
      border: 0.7px solid #ffffff;
      position: relative;
      padding-right: 30px;
      font-family: Regular;
      cursor: pointer;

      img {
        height: 8.5px;
      }

      &-name {
        margin-left: 7px;
      }

      &-arrow {
        transform: scale(1.2, 0.6) rotate(90deg);
        position: absolute;
        top: 2px;
        bottom: 0;
        right: 10px;
      }
    }

    &-airdrop {
      padding-right: 30px;
      position: relative;

      &-cancel {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 998;
      }

      &-menu {
        width: 100%;
        border: 0.5px solid #FFF;
        background: #020511;
        padding: 5px;
        top: 25px;
        position: absolute;
        z-index: 999;
        display: flex;
        align-items: center;
        flex-direction: column;

        p {
          padding: 5px 0;
          cursor: pointer;
          font-size: 14px;
        }

        p:hover {
          // font-size: 16px;
          color: rgb(192, 192, 192);
        }
      }
    }

    &-address {
      padding: 2px 0px 2px 0px;
      border: 0.7px solid #ffffff;
      position: relative;
      margin-left: 29px;
      font-family: Regular;

      &-name {
        padding: 2px 35px 2px 25px;
        ;
        cursor: pointer;
      }

      &-arrow {
        transform: scale(1.2, 0.6) rotate(90deg);
        position: absolute;
        top: 2px;
        bottom: 0;
        right: 10px;
        cursor: pointer;
      }

      &-menu {
        width: 100%;
        border: 0.5px solid #FFF;
        background: #020511;
        padding: 5px;
        top: 25px;
        position: absolute;
        z-index: 999;
        display: flex;
        justify-content: center;

        p {
          cursor: pointer;
        }
      }

      &-cancel {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 998;
      }

      &-loginMenu {
        width: 240px;
        border: 0.5px solid #FFF;
        background: #020511;
        padding: 10px;
        top: 25px;
        position: absolute;
        z-index: 999;
        display: flex;
        justify-content: center;
        flex-direction: column;



        div {
          padding: 10px;
          display: flex;
          flex-direction: row;

          img {
            height: 20px;
            padding: 0 15px 0 0;
          }

          p {
            cursor: pointer;
          }
        }

      }

      &-loginCancel {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 998;
      }

    }
  }

  .nav-medias {
    display: flex;
    align-items: center;

    .twitter {
      height: 24px;
    }

    .discord {
      height: 26px;
      margin-left: 32px;
    }
  }

  .nav-btn {
    position: relative;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 61px;
    cursor: pointer;

    &-icon {
      width: 14px;
    }
  }

  .nav-slide {
    position: absolute;
    right: 0;
    top: 30px;
    width: 269px;
    border: 1px solid white;
    background-color: black;

    &-cancel {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 998;
    }

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &0 {
        margin-top: 21px;
        margin-left: 25px;

        img {
          width: 26px;
        }

        span {
          margin-left: 36px;
        }
      }

      &1 {
        margin-top: 17px;
        margin-left: 26px;

        img {
          width: 21px;
        }

        span {
          margin-left: 40px;
        }
      }

      &2 {
        margin-top: 21px;
        margin-left: 26px;

        img {
          width: 24px;
        }

        span {
          margin-left: 37px;
        }
      }

      &3 {
        margin-top: 25px;
        margin-left: 26px;

        img {
          width: 25px;
        }

        span {
          margin-left: 36px;
        }
      }

      &4 {
        margin-top: 10px;
        margin-left: 22px;
        margin-bottom: 26px;

        img {
          width: 32px;
        }

        span {
          margin-left: 21px;
          opacity: 0.8;
        }
      }
    }

    &-divider {
      margin-left: 25px;
      margin-right: 15px;
      margin-top: 22px;
      height: 1px;
      background-color: white;
    }
  }

  &.nav-shrink {
    @media screen and (max-width: 1800px) {
      padding-top: 10px;

      .nav-logo {
        height: 54px;
      }

      .nav-menu {
        // margin-left: 37px;
        // transform: translateY(10px);
        // padding-top: 10px;

        &-item {
          padding: 0 20px;
          font-size: 14px;

          &-with-icon {
            padding: 0 20px;
            font-size: 14px;
          }

          &.airdrop {
            padding: 3px 12px;
            // margin-left: 30px;
            font-size: 13px;
            transform: translateY(-4px);
          }
        }
      }

      .nav-tools {
        &-network {
          font-size: 14px;
        }

        &-address {
          font-size: 14px;
        }
      }

      .nav-slide {
        border-width: 0.7px;
        font-size: 14px;
        width: 250px;
      }

      .nav-btn {
        margin-left: 40px;
      }
    }
  }
}
</style>